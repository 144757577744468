<template>
  <module
      ref="module"
      id="order-management"
      titleIcon="fa fa-usd"
      :title="$t('wallet_order_title')"
      :filters.sync="filters"
      :fixedFilters="fixedFilters"
      api-url="WALLETURL"
      url="/order/search"
  >
    <template slot="toolbar-global">
      <div class="mr-1" v-if="isAdmin">
        <fg-input
            id="userIdSearch"
            type="email"
            v-model="fixedFilters.userid"
            addon-right="fa fa-step-forward"
            class="input-toolbar"
            :placeholder="`${$t('userid')}`"
            @change="filterByUser"
        >
          <button
              slot="addonRight"
              class="btn btn-xs btn-primary"
              @click="filterByUser"
          >
            <i class="fa fa-step-forward"></i>
          </button>
        </fg-input>
      </div>
    </template>

    <template slot="table-columns">
      <el-table-column :label="$t('action')" prop="integration_data" align="center" width="100px">
        <template slot-scope="{row}">
          <p-button class="px-2 mr-1"
                    type="info"
                    v-if="isAdmin"
                    size="sm"
                    round
                    @click="showInfo(row.integration_data)"
          >
            <i class="fa fa-info-circle"/>
          </p-button>
          <p-button class="px-2 mr-1"
                    type="primary"
                    :disabled="!canPay(row)"
                    size="sm"
                    round
                    @click="togglePaymentModal(row)"
          >
            <i class="fa fa-credit-card"/>
          </p-button>
        </template>
      </el-table-column>
      <el-table-column :label="$t('status')" prop="status" align="center" width="160px">
        <template slot-scope="{row}">
          <badge :type="getOrderStatus(row.status).color" pill> {{ getOrderStatus(row.status).label }}</badge>
        </template>
      </el-table-column>
      <el-table-column :label="`${$t('chargedtxt')}`" prop="charged" width="120px">
        <template slot-scope="{row}">
            <span>
             <b>{{ getCurrencySymbol(row.currency) }} {{ parseFloat(row.charge).toFixed(2) }}</b>
            </span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('value')" prop="value" width="120px">
        <template slot-scope="{row}">
            <span>
             <b>$ {{ parseFloat(row.value).toFixed(2) }}</b>
            </span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('paymentmethod')" prop="method.label" width="120px" show-overflow-tooltip>
        <template slot-scope="{row}">
          <small>
            <b>{{ row.method.label }}</b>
          </small>
        </template>
      </el-table-column>
      <el-table-column :label="$t('userid')" prop="userid" show-overflow-tooltip>
        <template slot-scope="{row}">
          <small>
            <b>{{ row.userid }}</b>
          </small>
        </template>
      </el-table-column>
      <el-table-column :label="$t('date')" prop="created_dt" width="140px" align="center">
        <template slot-scope="{row}">
          <small>
            <b>{{ getFormattedDate(row.created_dt) }}</b>
          </small>
        </template>
      </el-table-column>
      <el-table-column :label="$t('helper_excentry')" prop="exchange_rate" align="center" width="120px">
        <template slot-scope="{row}">
          <small>
            <b>{{ getCurrencySymbol(row.currency) }} {{ parseFloat(row.exchange_rate).toFixed(2) }}</b>
          </small>
        </template>
      </el-table-column>
      <el-table-column :label="$t('tranid')" prop="transaction_id" align="center" width="300px">
        <template slot-scope="{row}">
          <small>
            <b>{{ row.transaction_id }}</b>
          </small>
        </template>
      </el-table-column>
      <el-table-column :label="$t('type')" prop="type" align="center" width="120px">
        <template slot-scope="{row}">
          <badge :type="row.type === 'deposit' ? 'success' : 'warning'" pill>
            {{ `${row.type === 'deposit' ? $t('topuptitle') : $t('whithdraw')}` }}
          </badge>
        </template>
      </el-table-column>
    </template>
    <template slot="filter-field">
      <fg-input v-model="filters.transaction_id"/>
      <order-type-select v-model="filters.type"/>
      <order-status-select v-model="filters.status"/>
    </template>

    <template slot="after-tc">
      <div v-if="inprogress" class="text-center">
        <h2>{{ inprogresstxt }}</h2>
        <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
      </div>

      <div v-if="!inprogress">

        <modal :show="transferSenhorMoney">
          <template slot="header">
            <div class="col-md-12 text-center">
              <img src="/static/img/senhormoney.png" style="transform: scale(0.75)"/>
            </div>
          </template>
          <template>
            <div class="col-md-12">
              <p style="text-align: justify">{{ $t('senhormoneyobs4') }} {{ $t('senhormoneyobs5') }}</p>
              <p class="text-info" style="margin-top: 10px">
                <i class="fa fa-info-circle"></i>
                {{ $t('senhormoneyobs6') }}
              </p>
            </div>
          </template>
          <template slot="footer">
            <div class="left-side">
              <p-button type="info" link v-on:click="senhorMoney()">
                {{ $t('follow_instruction') }}
              </p-button>
            </div>
            <div class="divider"></div>
            <div class="right-side">
              <p-button type="danger" v-on:click="transferSenhorMoney = false" link>
                {{ $t('cancel') }}
              </p-button>
            </div>
          </template>
        </modal>

        <!-- Modal for New or Update Own Entry -->
        <modal :show.sync="modal" headerClasses="justify-content-center">
          <h4 slot="header" class="title title-up" v-if="newentry">{{ $t('newentrytitle') }}</h4>
          <h4 slot="header" class="title title-up" v-else>{{ $t('upentrytitle') }}</h4>
          <div :class="`alert-${modal_alert_type}`" class="alert alert-dismissible fade show" v-if="modal_alert">
            <button type="button" aria-hidden="true" class="close" data-dismiss="alert" aria-label="Close"
                    v-on:click="closeModalAlert">
              <i class="nc-icon nc-simple-remove"></i>
            </button>
            <span>{{ modal_alert_msg }}</span>
          </div>


          <div class="row">
            <div class="col-12" v-if="selling">
              <small class="text-muted">{{ $t('helper_maxentry') }}</small>
              <fg-input v-model="myentry.max_sale" addon-left-icon="fa fa-chevron-up" type="number" min="10" step="1"
                        :placeholder="helper_maxentry"></fg-input>
            </div>
            <div class="col-12" v-else>
              <small class="text-muted">{{ $t('helper_minentry') }}</small>
              <fg-input v-model="myentry.min_sale" addon-left-icon="fa fa-chevron-up" type="number" min="10" step="1"
                        :placeholder="helper_minentry"></fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mx-auto text-center">
              <div style="display: inline-flex;">
                <div class="info-icon info-l10 info-t5">
                  <el-tooltip :content="exc_consent_txt" :open-delay="100" placement="top" popper-class="adjust-width">
                    <i class="nc-icon nc-alert-circle-i"></i>
                  </el-tooltip>
                </div>
                <p-checkbox v-model="myentry.public_viewed" :disabled="!newentry">
            <span class="allupper">
              {{ $t('listedpublically') }}
            </span>
                </p-checkbox>
              </div>
            </div>
          </div>

          <template slot="footer">
            <div class="py-3 mx-auto" v-if="modalinprogress">
              <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda;"></i>
            </div>
            <div class="left-side" v-if="modalinprogress == false">
              <p-button @click.prevent="validate" link v-if="newentry" :disabled="myentry.public_viewed == false">
                {{ $t('newentrybtn') }}
              </p-button>
              <p-button @click.prevent="validate" link v-else>{{ $t('upentrybtn') }}</p-button>
            </div>
            <div class="divider" v-if="modalinprogress == false"></div>
            <div class="right-side" v-if="modalinprogress == false">
              <p-button type="danger" v-on:click="closeModalAlert" link>{{ $t('cancel') }}</p-button>
            </div>
          </template>
        </modal>
        <!-- End Modal -->

        <!-- Modal for Delete Entry -->
        <modal :show.sync="modalconfirm" headerClasses="justify-content-center">
          <h4 slot="header" class="title title-up">{{ $t('delentrytitle') }}</h4>
          <p></p>
          <div :class="`alert-${modal_alert_type}`" class="alert alert-dismissible fade show" v-if="modal_alert">
            <button type="button" aria-hidden="true" class="close" data-dismiss="alert" aria-label="Close"
                    v-on:click="closeModalConfirmAlert">
              <i class="nc-icon nc-simple-remove"></i>
            </button>
            <span>{{ modal_alert_msg }}</span>
          </div>

          <div class="row">
            <div class="col-12">
              {{ $t('confirmdelentry') }}
            </div>
          </div>

          <template slot="footer">
            <div class="py-3 mx-auto" v-if="modalinprogress">
              <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda;"></i>
            </div>
            <div class="left-side" v-if="modalinprogress == false">
              <p-button @click.prevent="confirmDeletion" link>{{ $t('deletevps') }}</p-button>
            </div>
            <div class="divider" v-if="modalinprogress == false"></div>
            <div class="right-side" v-if="modalinprogress == false">
              <p-button type="danger" v-on:click="closeModalConfirmAlert" link>{{ $t('cancel') }}</p-button>
            </div>
          </template>
        </modal>
        <!-- End Modal -->

        <!-- Modal -->
        <modal :show="modalPayPal">
          <template slot="header">
            <h5>{{ $t('makeyourdepositwithpaypal') }}</h5>
          </template>

          <template v-if="paypalModalState == 5" slot="default">
            <h5>{{ $t('sorry') }}</h5>
            <span>{{ $t('paypalwentwrong_internal') }}</span>
          </template>

          <template v-if="paypalModalState == 4" slot="default">
            <h5>{{ $t('sorry') }}</h5>
            <span>{{ $t('paypalwentwrong_external') }}</span>
          </template>

          <template v-if="paypalModalState == 3" slot="default">
            <h5>{{ $t('thankyou') }}</h5>
            <span>{{ $t('paypalrequestconfirmed') }}</span>
          </template>

          <template v-if="paypalModalState == 2" slot="default">
            <div class="text-center">
              <h5>{{ $t('paypalconfirmation') }} </h5>
              <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
            </div>
          </template>

          <template v-if="paypalModalState == 1" slot="default">
            <div class="row">
              <div class="col-md-12">
                <div class="alert alert-info" v-if="!failPayPalValue">
                  <i class="fa fa-info-circle"></i>
                  {{ $t('depositreceive4percenttax', getPercentage()) }}
                </div>
                <div class="alert alert-danger" v-if="failPayPalValue">
                  <i class="fa fa-info-circle"></i>
                  {{ $t('inserdepositvalue') }}
                </div>
              </div>
            </div>

            <div class="row" v-if="!paypalValueConfirmed">

              <div class="col-md-6" v-if="exchange.currency == 'eur'">
                <div class="form-group">
                  <label>{{ $t('helper_excentry') }}</label>
                  <span class="form-control text-right">
              {{ parseFloat(rates.EUR + 0.01).toFixed(2) }}
            </span>
                </div>
              </div>

              <div class="col-md-6" v-if="exchange.currency == 'usd'">
                <div class="form-group">
                  <label>{{ $t('helper_excentry') }}</label>
                  <span class="form-control text-right">1.00</span>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label>{{ txt.paypal.value }}</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
              <span class="input-group-text" style="height: 40px">
                $
              </span>
                    </div>
                    <money
                        class="form-control input-sm text-right"
                        style="height: 40px"
                        v-model="paypalHko"></money>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" v-if="exchange.currency == 'usd'">
              <div class="col-md-12">
                <div class="form-group">
                  <label>{{ $t('cost') }}</label>
                  <span class="form-control text-right">
              <span> USD <i class="fa fa-dollar"></i></span>
              {{ applyMask(paypalValue) }}
            </span>
                </div>
              </div>
            </div>

            <div class="row" v-if="exchange.currency == 'eur'">
              <div class="col-md-12">
                <div class="form-group">
                  <label>{{ $t('cost') }}</label>
                  <span class="form-control text-right">
              <span v-if="exchange.currency == 'usd'">USD <i class="fa fa-dollar"></i></span>
              <span>EUR <i class="fa fa-euro"></i></span>
              {{ applyMask(paypalValue) }}
            </span>
                </div>
              </div>
            </div>

            <div class="row" v-if="paypalValueConfirmed">
              <div class="col-md-12">
                <div id="divpaypal" ref="paypal"></div>
              </div>
            </div>
          </template>

          <template v-if="paypalModalState == 1" slot="footer">

            <div class="left-side" v-if="!paypalValueConfirmed">
              <p-button type="success" link v-on:click="confirmValuePayPal()">
                {{ $t('confirm') }}
              </p-button>
            </div>

            <div class="divider" v-if="!paypalValueConfirmed"></div>

            <div class="right-side" v-if="!paypalValueConfirmed">
              <p-button type="danger" v-on:click="modalPayPal = false" link>
                {{ $t('cancel') }}
              </p-button>
            </div>

            <p-button
                v-if="paypalValueConfirmed"
                type="danger"
                link
                v-on:click="closePayPalLoadedModal()">
              {{ $t('cancel') }}
            </p-button>

          </template>

          <template v-if="paypalModalState == 3" slot="footer">
            <p-button
                type="success"
                link
                v-on:click="modalPayPal = false">
              {{ $t('gotit') }}
            </p-button>
          </template>

          <template v-if="paypalModalState == 4 || paypalModalState == 5" slot="footer">
            <p-button
                type="success"
                link
                v-on:click="modalPayPal = false">
              {{ $t('confirm') }}
            </p-button>
          </template>

        </modal>

        <!-- Modal for withdrawl description   -->
        <modal :show.sync="modalDescription" required headerClasses="justify-content-center">
          <h4 slot="header" class="title title-up">{{ $t('withdrawals') }} {{ $t('description') }}</h4>
          <p></p>
          <div class="row form-group">
            <div class="col-12">
              <h6> {{ $t('withdrawals_description_txt') }} </h6>
              <textarea v-model="model.instruction" class="form-control" rows="5"></textarea>
            </div>
          </div>
          <template slot="footer">
            <div class="py-3 mx-auto" v-if="modalinprogress">
              <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda;"></i>
            </div>
            <div class="left-side" v-if="modalinprogress == false">
              <p-button v-on:click="sendreq" link>{{ $t('sendrequest') }}</p-button>
            </div>
            <div class="divider" v-if="modalinprogress == false"></div>
            <div class="right-side" v-if="modalinprogress == false">
              <p-button type="danger" v-on:click="modalDescription = false" link>{{ $t('cancel') }}</p-button>
            </div>
          </template>
        </modal>

        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <h5>
                <i class="nc-icon nc-refresh-69"></i>
                {{ $t('withdrawals_request') }}
              </h5>
            </div>
          </div>
        </div>

        <hr>

        <div>
          <div>
            <div class="col-md-12">
              <h5 v-if="exchange.currency == 'pick'">
                <i class="fa fa-info-circle"></i>
                {{ $t('paymentinstructionsinfo') }}
              </h5>
              <div class="row">
                <div class="col-md-12 text-center">
                  <button class="btn btn-success" v-on:click="setCurrency('brl')">
                    <i>R$</i>
                    BRL
                  </button>
                  <button class="btn btn-danger" v-on:click="setCurrency('usd')">
                    <i class="fa fa-usd"></i>
                    USD
                  </button>
                  <button class="btn btn-primary" v-on:click="setCurrency('eur')">
                    <i class="fa fa-eur"></i>
                    EUR
                  </button>
                  <button class="btn btn-default" v-on:click="setCurrency('crypto')">
                    <i class="fa fa-bitcoin"></i>
                    Crypto
                  </button>
                </div>
              </div>

              <div class="alert alert-info" v-if="exchange.currency != 'pick'&& exchange.currency != 'brl'">
                <i class="fa fa-info-circle"></i>
                {{ $t('withdrawalsinstructionsinfo') }}
              </div>

              <div v-if="exchange.currency == 'brl'">

                <el-table
                    :emptyText="txt.emptyrow"
                    :data="methods_brl">

                  <el-table-column
                      property="name"
                      :label="method"
                      width="150px">
                  </el-table-column>

                  <el-table-column :label="txt.details" min-width="250px">
                    <div slot-scope="scope">
                <span v-if="scope.row.details">
                  {{ scope.row.details }}
                </span>
                    </div>
                  </el-table-column>

                  <el-table-column
                      width="250px"
                      :label="txt.options">
                    <template slot-scope="scope">
                      <button class="btn btn-primary" v-on:click="transferSenhorMoney = true" style="width: 220px">
                        {{ $t('optionstxt') }}
                      </button>
                    </template>
                  </el-table-column>
                  <!-- </scope> -->
                </el-table>


              </div>

              <div class="row" v-show="!emptyMethods">
                <div class="col-md-4">
                  <label>{{ $t('withdrawal_preferred_method') }}</label>
                  <div class="form-group">
                    <el-select v-model="selectedwithdrawalsMethods" v-on:change="selectMethod">
                      <el-option
                          class="select-default"
                          v-for="item in withdrawalsMethods"
                          :key="item.method" :label="item.method" :value="item.method">
                      </el-option>
                    </el-select>
                  </div>
                </div>

                <div class="col-md-4">
                  <label> {{ txt.methodFee }}</label>
                  <fg-input v-model="methodFeePlusFixed" disabled style="background-color: lightgray"></fg-input>
                </div>

                <div class="col-md-4">
                  <label> {{ txt.fundsreceivedafterfees }} </label>
                  <fg-input v-model="funds_receivedComp" disabled style="background-color: lightgray"></fg-input>
                </div>

                <div class="col-md-4">
                  <label> {{ txt.withdrawal_requested_amt }} </label>
                  <fg-input v-model="withdrawalAmount" v-on:keyup="requestAmountChanged" addon-right="$"></fg-input>
                </div>

                <div class="col-md-4">
                  <label> {{ txt.charged }} </label>
                  <fg-input v-model="variableCharge" disabled addon-right="$"></fg-input>
                </div>

                <div class="col-md-4 text-center" style="padding-top: 18px">
                  <p-button
                      v-if="!modalinprogress" wide type="info"
                      :disabled="disableSend"
                      v-on:click="writeDescription"
                  >
                    {{ $t('sendrequest') }}
                  </p-button>
                  <span class="text-info" v-if="modalinprogress">
                <i class="fa fa-spin fa-cog fa-2x fa-fw"></i>
              </span>
                </div>

              </div>

            </div>

            <div v-if="exchange.currency != 'pick'">

              <div v-if="!showMarketplace"></div>
              <!-- marketplace -->
              <div v-if="!isbroker && showMarketplace" class="col-md-12">
                <h5>{{ $t('withdrawals') }} {{ $t('marketplace') }}</h5>
                <p>{{ $t('withdrawalsmarketplace') }}</p>
                <p>{{ $t('suggestedexchange') }}</p>
                <div class="row">
                  <div class="col-md-6">
                    <fg-input class="input-sm"
                              placeholder="$"
                              v-model="exchange.withdrawal.hko"
                              addon-right="$" v-on:keyup="calcByCurrencyWith">
                    </fg-input>
                  </div>

                  <div class="col-md-6">
                    <fg-input class="input-sm"
                              :placeholder="exchange.currency"
                              v-model="exchange.exchange"
                              :addon-right="exchange.currency.toUpperCase()" v-on:keyup="calcByHKO">
                    </fg-input>
                  </div>

                </div>
              </div>
              <div v-if="showMarketplace" class="mr-2 ml-2">
                <!-- Table Fund -->
                <table-fund :tableData="tableData"
                            :sortby="sortby"
                            :pagination="pagination"
                            :propsToSearch="propsToSearch"
                            :tableColumns="tableColumns"
                            :clonedTableData="clonedTableData"
                            :myownentry="myownentry"
                            :walletbal="walletbal"
                            :seller="false"
                            :adminusr="adminusr"
                            @on-open-with="openModal"
                            @on-delete-entry="deleteEntry">
                </table-fund>

              </div>

            </div>

          </div>
          <!--        <div class="card-footer"></div>-->
        </div>
        <!-- withdrawals_request -->
        <div class="col-md-12 mt-3">


          <div class="row">
            <table-withdrawal
                :tableData="tableWithdrawals"
                :sortby="sortbywith"
                :pagination="paginationwith"
                :propsToSearch="propsToSearchwith"
                :tableColumns="tableColumnswith"
                :clonedTableData="clonedWithdrawalTableData"
                :adminusr="adminusr"
                @on-cancel-with="cancelWithdrawal">
            </table-withdrawal>
          </div>
        </div>

        <modal :show.sync="!!info.length" modalClasses="modal-lg">
          <template slot="header">
            <h3 class="mb-0">{{ $t('infotxt') }}</h3>
          </template>
          <pre>{{ info }}</pre>
          <template slot="footer">
            <p-button link type="warning" @click="showInfo('')">{{ $t('confirm') }}</p-button>
          </template>
        </modal>

        <modal :show.sync="showPaymentModal">
          <template slot="header">
            <h3 v-if="showPaymentModal" class="mb-0">{{ $t('paywith', [order.integration_data.payable_with]) }}</h3>
          </template>
          <template v-if="showPaymentModal">
            <div class="row">
              <div class="col-md-3"><small>{{ $t('tranid') }}:</small></div>
              <div class="col-md-8">
                {{ order.transaction_id }}
              </div>
              <div class="col-md-3"><small>{{ $t('value') }}:</small></div>
              <div class="col-md-8">
                <b>$ {{ parseFloat(order.value).toFixed(2) }}</b>
              </div>
              <div class="col-md-3"><small>{{ $t('charge') }}:</small></div>
              <div class="col-md-8">
                <b>{{ getCurrencySymbol(order.currency) }} {{ parseFloat(order.charge).toFixed(2) }}</b>
              </div>
            </div>
            <div class="text-center">
              <img :src="order.integration_data.pix.qrcode" height="200px"/>
              <br>
              <p-button type="success" link @click="openLink">{{ $t('open_payment_page') }}</p-button>
            </div>
          </template>
          <template slot="footer">
            <p-button link type="warning" @click="togglePaymentModal('')">{{ $t('confirm') }}</p-button>
          </template>
        </modal>

      </div>
    </template>
  </module>
</template>

<script>
import moment from "moment";
import {Badge, Button, Card, Modal, TabPane, Tabs} from "src/components/UIComponents";
import OrderStatusSelect from "@/components/Dashboard/Views/Dashboard/Wallet/OrderStatusSelect";
import OrderTypeSelect from "@/components/Dashboard/Views/Dashboard/Wallet/OrderTypeSelect";
import Constants from "@/assets/constants";
import {OrderStatus} from "@/models/wallet/OrderStatus"
import {getCurrency} from "@/util/CurrencyUtils";
import VueCookies from "vue-cookies";
import swal from "sweetalert2";
import {Option, Select, Tooltip} from "element-ui";
import TableFund from "@/components/Dashboard/Views/Components/TableFund.vue";
import TableWithdrawal from "@/components/Dashboard/Views/Components/TableWithdrawal.vue";
import Vue from "vue";
import money from "v-money";


const rules = Constants.methods.getRules();
const user_data = JSON.parse(localStorage.getItem('user-info'));
let entries = [];
let timeoutcount = 1500;

Vue.use(Select)
Vue.use(Option)
Vue.use(money, {precision: 0})

export default {
  name: "OrderManagement",
  components: {
    Badge,
    [Button.name]: Button,
    Card,
    Modal,
    OrderStatusSelect,
    OrderTypeSelect,
    [Tooltip.name]: Tooltip,
    TabPane,
    Tabs,
    TableFund,
    TableWithdrawal,
  },
  computed: {
    disableSend: {
      get() {
        return !this.funds_received || this.funds_received <= 0;
      }
    },
    variableFeeComp: {
      get() {
        if (this.variableCharge) {
          return (this.variableCharge.toFixed(2)) + ' ' + getCurrency(this.exchange.currency.toUpperCase());
        }
        return ''
      }
    },
    fixedFeeComp: {
      get() {
        if (this.withdrawalFixedFee) {
          return this.withdrawalFixedFee + ' ' + getCurrency(this.exchange.currency.toUpperCase());
        }
        return ''
      }
    },
    funds_receivedComp: {
      get() {
        let currency = this.exchange.currency.toUpperCase();
        if (currency == 'CRYPTO') {
          currency = 'USD';
        }
        if (this.funds_received) {
          return (this.funds_received.toFixed(2)) + ' ' + currency;
        }
        return this.funds_received + ' ' + currency;
      }
    },
    withdrawalTextWithCurreny: {
      get() {
        if (this.exchange.currency == 'pick') {
          return this.$t('withdrawals')
        } else {
          return this.$t('withdrawals') + ' (' + (this.exchange.currency).toUpperCase() + ') ';
        }
      }
    },
    isAdmin() {
      return rules.isAdmin;
    }
  },
  data() {
    return {
      filters: {
        transaction_id: '',
        type: '',
        status: '',
      },
      fixedFilters: {
        userid: user_data.email,
      },
      info: '',
      order: {},
      showPaymentModal: false,
      transferSenhorMoney: false,
      workingCurrency: 'usd',
      short: '',
      exchange: {
        currency: "pick",
        currencies: [
          {
            prop: 'pick',
            label: this.$t('selectcurrency')
          },
          {
            prop: 'usd',
            label: "$ USD"
          },
          {
            prop: 'eur',
            label: "€ EUR"
          },
          {
            prop: 'brl',
            label: "R$ BRL"
          }
        ],
        hko: 1.0,
        exchange: 0,
        withdrawal: {
          hko: 1.0,
          exchange: 0
        }
      },
      methods_crypt: [
        {
          "crypto": "Bitcoin",
          "isin": "XBT",
          "address": "3JDHhmi4h9W1Ldwc8TqXYqNAjGG6FQdJos",
          "tag": null,
          "deposit_minimum": "0.0001 XBT ",
          "deposit_fixed_fee": "free",
          "deposit_var_fee": "free",
          "withdraw_minimum": "$ 100",
          "withdraw_fixed_fee": "$ 10",
          "withdraw_var_fee": "0,95%",
          "note": this.$t('conversionnote'),
          "attention": this.$t('attentionnote')
        },
        {
          "crypto": "Ethereum ERC20",
          "isin": "ETH",
          "address": "0x465f945fd992fc1b9c4f00fca61cf1799d43094d",
          "tag": null,
          "deposit_minimum": "0.00001 ETH",
          "deposit_fixed_fee": "free",
          "deposit_var_fee": "free",
          "withdraw_minimum": "$ 100",
          "withdraw_fixed_fee": "$ 15",
          "withdraw_var_fee": "0,95%",
          "note": this.$t('conversionnote'),
          "attention": this.$t('attentionnote')
        },
        {
          "crypto": "Ripple",
          "isin": "XRP",
          "address": "rLHzPsX6oXkzU2qL12kHCH8G8cnZv1rBJh",
          "tag": "1111916622",
          "deposit_minimum": "0.0 XRP ",
          "deposit_fixed_fee": "free",
          "deposit_var_fee": "free",
          "withdraw_minimum": "$ 100",
          "withdraw_fixed_fee": "$ 0,50",
          "withdraw_var_fee": "0,95%",
          "note": this.$t('conversionnote'),
          "attention": this.$t('attentionnote')
        },
        {
          "crypto": "Cardano",
          "isin": "ADA",
          "address": "DdzFFzCqrht41pSf2wF1vWLYi67waWtTQA3vN7TpXofzYbEKzGhkhRkJsePmtiTCDXaZuPtE1gcEhJmXRSzcuUnDJaNSAHhJvz1ZuFZN",
          "tag": null,
          "deposit_minimum": "₳1.000000",
          "deposit_fixed_fee": "free",
          "deposit_var_fee": "free",
          "withdraw_minimum": "$ 100",
          "withdraw_fixed_fee": "$ 3,50",
          "withdraw_var_fee": "0,95%",
          "note": this.$t('conversionnote'),
          "attention": this.$t('attentionnote')
        },
        {
          "crypto": "USDC ERC20",
          "isin": "USDC",
          "address": "0x465f945fd992fc1b9c4f00fca61cf1799d43094d",
          "tag": null,
          "deposit_minimum": "USDⓒ1.0000",
          "deposit_fixed_fee": "free",
          "deposit_var_fee": "free",
          "withdraw_minimum": "$ 100",
          "withdraw_fixed_fee": "$ 20",
          "withdraw_var_fee": "0,95%",
          "note": this.$t('conversionnote'),
          "attention": this.$t('attentionnote')
        },
        {
          "crypto": "Tether USD",
          "isin": "USDT",
          "address": "0x465f945fd992fc1b9c4f00fca61cf1799d43094d",
          "tag": null,
          "deposit_minimum": "USD₮1.0000",
          "deposit_fixed_fee": "free",
          "deposit_var_fee": "free",
          "withdraw_minimum": " $100",
          "withdraw_fixed_fee": "$ 7,50",
          "withdraw_var_fee": "0,95%",
          "note": this.$t('conversionnote'),
          "attention": this.$t('attentionnote')
        }
      ],
      methods_brl: [
        {
          id: 1,
          currency: ['BRL'],
          name: this.$t('ted'),
          instructionus: '-',
          instructioneur: '-',
          percentCharge: "0",
          fixedCharge: 0,
          details: this.$t('thirdpartyoptionprovided'),
        },
        {
          id: 2,
          currency: ['BRL'],
          name: this.$t('pix'),
          instructionus: '-',
          instructioneur: '-',
          percentCharge: "0",
          fixedCharge: 0,
          details: this.$t('thirdpartyoptionprovided'),
        },
        {
          id: 3,
          currency: ['BRL'],
          name: this.$t('prepaydcard'),
          instructionus: '-',
          instructioneur: '-',
          percentCharge: "0",
          fixedCharge: 0,
          details: this.$t('thirdpartyoptionprovided'),
        },
      ],
      model: {
        amount: 100,
        method: null,
        instruction: null,
        limit: 0
      },
      paymethods: [
        {
          'prop': 'wire',
          'label': 'Bank Wire',
          'fee': 1.5
        },
        {
          'prop': 'neteller',
          'label': 'Neteller',
          'fee': 3
        },
        {
          'prop': 'skrill',
          'label': 'Skrill',
          'fee': 3
        },
        {
          'prop': 'paypal',
          'label': 'PayPal',
          'fee': 3
        }
      ],
      inprogress: true,
      inprogresstxt: this.$t('loadingtxt') + " " + this.$t('withdrawals') + ". " + this.$t('pleasewait'),
      marketentries: [],
      rowentries: [],
      exc_consent_txt: this.$t('exc_consent_txt'),
      namecol: this.$t('nametxt'),
      modal: false,
      modal_alert: false,
      modal_alert_msg: null,
      modal_alert_type: 'danger',
      modalinprogress: false,
      modalconfirm: false,
      newentry: true,
      helper_descentry: this.$t('helper_descentry'),
      helper_excentry: this.$t('helper_excentry'),
      helper_currentry: this.$t('helper_currentry'),
      helper_minentry: this.$t('helper_minentry'),
      helper_maxentry: this.$t('helper_maxentry'),
      txt: {
        options: this.$t('optionstxt'),
        methodFee: this.$t('paymentmethod') + " " + this.$t('feetxt'),
        paymentMethod: "Payment Method",
        percentFee: "% " + this.$t('feetxt'),
        fixedfee: this.$t('fixed_charge'),
        charged: this.$t('amount_charged'),
        paypal: {
          value: this.$t('deposit_value')
        },
        fundsreceivedafterfees: this.$t('fundsreceivedafterfees'),
        withdrawalsetlimit: this.$t('withdrawal_set_limit'),
        withdrawal_requested_amt: this.$t('withdrawal_requested_amt'),
        variablefee: this.$t('variablefee'),
        emptyrow: this.$t('norecordsfounded'),
        details: this.$t('detailstxt'),
      },
      myentry: {
        exchange: null,
        currency: null,
        description: null,
        min_sale: 10,
        max_sale: 10,
        public_viewed: false,
        type: null
      },
      actionlbl: this.$t('action'),
      msginterest_comment: null,
      helper_descmsg: this.$t('msginterest_description'),
      lister: {
        'userid': null,
        'first_name': null,
        'last_name': null
      }
      ,
      interester: {
        'userid': null,
        'first_name': null,
        'last_name': null
      },
      perpageTxt: this.$t('perpage'),
      sortbyTxt: this.$t('sortby'),
      colSort: 'exchange',
      type: "credits",
      withcolSort: 'exchange',
      instructionus: this.$t('instructionus'),
      instructioneur: this.$t('instructioneur'),
      cost: this.$t('withdrawal_fee'),
      method: this.$t('paymentmethod'),
      /** Withdrawals **/
      withsortby: {
        column: 'fullname',
        columns: [
          {prop: 'fullname', label: this.$t('nametxt')},
          {prop: 'userid', label: this.$t('email')},
          {prop: 'phone', label: this.$t('phone')},
        ]
      },
      withpagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      withsearchQuery: '',
      withtableColumns: [
        {prop: 'userid', label: this.$t('email')},
        {prop: 'phone', label: this.$t('phone')},
      ],
      withtableData: [],
      /** Top-Up **/
      sortby: {
        column: 'fullname',
        columns: [
          {prop: 'fullname', label: this.$t('nametxt')},
          {prop: 'userid', label: this.$t('email')},
          {prop: 'phone', label: this.$t('phone')},
        ]
      },
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['fullname', 'userid', 'phone'],
      tableColumns: [
        {prop: 'userid', label: this.$t('email')},
        {prop: 'phone', label: this.$t('phone')},
      ],
      /** Withdrawal Requests **/
      sortbywith: {
        order: 'desc',
        orders: [
          {
            prop: 'asc',
            label: this.$t('ascending')
          },
          {
            prop: 'desc',
            label: this.$t('descending')
          }
        ],
        column: 'request_dt',
        columns: [
          {prop: 'request_dt', label: this.$t('requestedon')},
          {prop: 'tran_id', label: this.$t('tranid')},
          {prop: 'amount_requested', label: this.$t('withdrawal_requested_amt')},
          {prop: 'currency', label: this.$t('currency')},
          {prop: 'payment_method', label: this.$t('paymentmethod')},
          {prop: 'decision_dt', label: this.$t('decidedon')},
          {prop: 'amount_to_paid', label: this.$t('withdrawal_fund_amt')},
          {prop: 'approval_state', label: this.$t('status')}
        ]
      },
      paginationwith: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuerywith: '',
      propsToSearchwith: ['tran_id', 'currency', 'payment_method', 'approval_state'],
      tableColumnswith: [
        {
          prop: 'request_dt',
          label: this.$t('requestedon')
        },
        {
          prop: 'amount_requested',
          label: this.$t('amount')
        },
        {
          prop: 'currency',
          label: this.$t('currency')
        },
        {
          prop: 'exchange_rate',
          label: this.$t('exchange')
        },
        {
          prop: 'payment_method',
          label: this.$t('paymentmethod')
        },
        {
          prop: 'decision_dt',
          label: this.$t('decidedon')
        },
        {
          prop: 'amount_to_paid',
          label: this.$t('withdrawal_fund_amt')
        },
        {
          prop: 'approval_state',
          label: this.$t('status')
        }
      ],
      tableData: [],
      clonedTableData: [],
      clonedWithTableData: [],
      tableWithdrawals: [],
      clonedWithdrawalTableData: [],
      tablePaymentInstruction: [
        {
          name: 'Neteller',
          cost: "2%",
          fixedfee: "-"
        },
        {
          name: 'PayPal',
          cost: "4%",
          fixedfee: "-"
        },
        {
          name: 'Skrill',
          cost: "3%",
          fixedfee: "-"
        },
        {
          name: 'BANK WIRE (SEPA / SWIFT)',
          cost: "1.95%",
          fixedfee: "-"
        },
      ],
      myownentry: false,
      adminusr: false,
      isbroker: false,
      baccs: {
        accounts: []
      },
      rates: {
        EUR: 0.8510, // 1.1750
        BRL: 0,
        USD: 1.01,
        eur: 0.8510, // 1.1750
        brl: 0,
        usd: 1.01
      },
      statsCards: [],
      vpsusers: [],
      selling: true,
      walletbal: 0,
      ueml: null,
      typefordel: null,
      withTableCounter: 0,
      fee_type: null,
      fee_percent: 0,
      funds_received: 0,
      modalPayPal: false,
      paypalValue: 0,
      paypalHko: 0,
      paypalValueConfirmed: false,
      paypalModalState: 1,
      failPayPalValue: false,
      client_id: 'AXil5553QeFifz2yVy7UC3z5MmtRh7waZyheAo0sxfMOzOl6469kCdrDyDVbmein4hVfANlriW0kMXz4',
      currentWithdrawals: '',
      withdrawals: [
        {
          currency: {
            label: 'USD',
            methods: [
              // {
              //   method: 'Neteller',
              //   fixed_fee: 2.95,
              //   var_fee: 5.45,
              // },
              // {
              //   method: 'Skrill',
              //   fixed_fee: 2.95,
              //   var_fee: 5.45,
              // },
              {
                method: 'PayPal',
                fixed_fee: 2.95,
                var_fee: 2.95,
              },
              {
                method: 'Bank Wire',
                fixed_fee: 2.95,
                var_fee: 1.95,
              },
              {
                method: 'SM Pay',
                fixed_fee: 0,
                var_fee: 0.95,
              },
            ]
          }
        },
        {
          currency: {
            label: 'EUR',
            methods: [
              // {
              //   method: 'Neteller',
              //   var_fee: 5.45,
              //   fixed_fee: 2.95,
              // },
              // {
              //   method: 'Skrill',
              //   fixed_fee: 2.95,
              //   var_fee: 5.45,
              // },
              {
                method: 'PayPal',
                fixed_fee: 2.95,
                var_fee: 2.95,
              },
              {
                method: 'Bank Wire',
                fixed_fee: 2.95,
                var_fee: 0.95,
              }
            ]
          }
        },
        {
          currency: {
            label: 'BRL',
            methods: []
          }
        },
        {
          currency: {
            label: 'CRYPTO',
            methods: [
              {
                method: 'Bitcoin (XBT)',
                short: 'USD',
                fixed_fee: 3.00,
                var_fee: 0.95,
              },
              {
                method: 'Ethereum (ETH)',
                short: 'USD',
                fixed_fee: 8.00,
                var_fee: 0.95,
              },
              {
                method: 'Ripple (XRP)',
                short: 'USD',
                fixed_fee: 0.50,
                var_fee: 0.95,
              },
              {
                method: 'Cardano (ADA)',
                short: 'USD',
                fixed_fee: 3.00,
                var_fee: 0.95,
              },
              {
                method: 'USD Coin (USDC)',
                short: 'USD',
                fixed_fee: 10,
                var_fee: 0.95,
              },
              {
                method: 'USDT ERC20',
                short: 'USD',
                fixed_fee: 8.00,
                var_fee: 0.95,
              },
              {
                method: 'USDT TRC20',
                short: 'USD',
                fixed_fee: 3.00,
                var_fee: 0.95,
              },
            ]
          }
        }
      ],
      selectedwithdrawalsMethods: '',
      withdrawalsMethods: {},
      methodFee: '',
      varFee: 0,
      withdrawalFixedFee: 0.50,
      variableCharge: '',
      withdrawalAmount: 100,
      methodFeePlusFixed: '',
      showMarketplace: true,
      emptyMethods: false,
      modalDescription: false
    }
  },
  methods: {
    filterByUser() {
      this.$refs.module.search();
    },
    togglePaymentModal(order) {
      if (!order) {
        this.order = {};
        this.showPaymentModal = false;
      } else {
        this.order = order;
        this.order.integration_data = JSON.parse(this.order.integration_data);
        this.showPaymentModal = true;
      }
    },
    openLink() {
      if (this.order.integration_data.secure_url) {
        window.open(this.order.integration_data.secure_url, '_blank');
        this.togglePaymentModal('');
      }
    },
    showInfo(info) {
      this.info = info;
    },
    canPay(row) {
      return row.method.value === 'pix' && this.getOrderStatus(row.status).canPay;
    },
    getCurrencySymbol(code) {
      return getCurrency(code.toUpperCase());
    },
    getOrderStatus(value) {
      return OrderStatus.find(s => s.value === value);
    },
    getFormattedDate(date) {
      return moment(date).format("YYYY.MM.DD HH:mm")
    },
    senhorMoney() {
      this.transferSenhorMoney = false;
      window.open('https://senhormoney.com/gestao-de-usuario/entrar/', '_blank');
    },
    getPercentage() {
      if (this.workingCurrency == 'eur') {
        return ['€0.35 + 3.8%']
      }
      return ['$0.30 + 5.1%']
    },
    closePayPalLoadedModal() {
      document.getElementById('divpaypal').innerHTML = ''
      this.modalPayPal = false;
    },
    confirmationHandler(response) {
      this.paypalModalState = response.success ? 3 : 5;
    },
    errorHandler() {
      this.paypalModalState = 5;
    },
    setCurrency(currency) {
      this.exchange.currency = currency
      this.tabchanged(currency)
    },
    responseHandler(details) {
      if (details.status == 'VOIDED') {
        this.paypalModalState = 4;
        return;
      }
      this.paypalModalState = 2;
      this.$paypalUserConfirm({
        userid: this.ueml,
        value: this.paypalHko,
        currency: this.workingCurrency,
        exchange_rate: (this.workingCurrency == 'eur' ? this.rates.EUR : this.rates.USD) + 0.01,
        paypal_response: details,
      }).then(this.confirmationHandler, this.errorHandler)
    },
    setLoaded: function (params) {
      if (!document.getElementById('pcc')) {
        return
      }
      window.paypal.Buttons({
        style: {
          layout: 'horizontal',
          size: 'small',
          color: 'gold',
          shape: 'rect',
        },
        createOrder: function (data, actions) {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: params.getValue(),
              }
            }],
            application_context: {
              shipping_preference: 'NO_SHIPPING'
            }
          });
        },
        onApprove: function (data, actions) {
          return actions.order.capture()
              .then(params.handler)
        }
      }).render(this.$refs.paypal);
    },
    loadPayPal() {
      if (document.getElementById('pcc')) {
        document.getElementById('pcc').remove()
      }
      const script = document.createElement("script");
      script.id = 'pcc'
      const currency = this.workingCurrency.toUpperCase();
      script.src = "https://www.paypal.com/sdk/js?currency=" + currency + "&client-id=" + this.client_id;
      script.addEventListener("load", () => {
        this.setLoaded({
          handler: this.responseHandler,
          getValue: () => {
            return parseFloat(this.paypalValue).toFixed(2);
          }
        });
      });
      document.body.appendChild(script);
    },
    confirmValuePayPal() {
      if (this.paypalHko == 0) {
        this.failPayPalValue = true;
        return
      }
      this.paypalValueConfirmed = true
      this.loadPayPal()
    },
    applyMask(value) {
      return (parseFloat(value).toFixed(2) + "").replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    },
    paypalModal() {
      this.paypalHko = 0;
      this.paypalValue = 0;
      this.paypalValueConfirmed = false;
      this.paypalModalState = 1;
      this.modalPayPal = true;
    },
    cancelWithdrawal(tranid) {
      this.$opWithdrawRequest(this.ueml, tranid, 'reject').then(this.closeModalAlertPostUpsertReq, this.failWith);
    },
    onActivatedTab(tab) {
      this.tabchanged(tab.id);
    },
    selectCurrency(curr) {
      this.clearFields()
      let aux = this.withdrawals.filter(item => {
        if (item.currency.label == curr.toUpperCase()) {
          this.withdrawalsMethods = item.currency.methods
        }
      })
      if (this.withdrawalsMethods.length == 0) {
        this.emptyMethods = true
      }
    },
    selectMethod(curr) {
      this.clearFields()
      this.selectedwithdrawalsMethods = curr
      const method = this.withdrawalsMethods.filter(m => m.method == curr)[0];

      this.short = method.short;
      this.methodFee = method.fixed_fee;
      this.varFee = method.var_fee;


      if (method.var_fee == 0) {
        this.methodFeePlusFixed = `${getCurrency(this.exchange.currency.toUpperCase())} ${method.fixed_fee}`;
      } else {
        this.methodFeePlusFixed = `${getCurrency(this.exchange.currency.toUpperCase())} ${method.fixed_fee} + ${method.var_fee}%`;
      }

      this.requestAmountChanged()
    },
    requestAmountChanged() {
      let method = this.selectedwithdrawalsMethods;
      let currency = this.workingCurrency.toUpperCase()

      let cur = this.withdrawals.filter(item => {
        return item.currency.label == currency
      })[0];

      let met = cur.currency.methods.filter(item => {
        return item.method == method;
      })[0];

      this.variableCharge = (this.withdrawalAmount * (met.var_fee / 100)) + met.fixed_fee

      if (currency == 'EUR') {
        this.funds_received = parseFloat(parseFloat((this.withdrawalAmount - this.variableCharge) * this.rates.EUR).toFixed(2))
      } else {
        this.funds_received = this.withdrawalAmount - this.variableCharge
      }

      this.variableCharge = parseFloat(this.variableCharge.toFixed(2));
    },
    clearFields() {
      this.funds_received = ''
      this.variableCharge = ''
      this.selectedwithdrawalsMethods = ''
      this.emptyMethods = false
    },
    tabchanged(curr) {
      if (curr == 'pick') {
        this.emptyMethods = true;
        this.showMarketplace = false;
        return;
      }
      this.selectCurrency(curr.toUpperCase())
      this.workingCurrency = curr;
      this.exchange.currency = curr;
      var re = new RegExp(curr, 'gi');

      this.withtableData = [];
      for (var j = 0; j < this.clonedWithTableData.length; j++) {
        if (this.clonedWithTableData[j].currency.match(re) !== null) {
          this.withtableData.push(this.clonedWithTableData[j]);
        }
      }
      this.withTableCounter += 1;

      // Top-Up
      this.tableData = [];
      for (var i = 0; i < this.clonedTableData.length; i++) {
        if (this.clonedTableData[i].currency.match(re) !== null) {
          this.tableData.push(this.clonedTableData[i]);
        }
      }

      this.showMarketplace = !this.tableData.length <= 0;

      if (this.rates.withdrawal) {
        this.calcByCurrencyWith();
      }
    },
    failWith(resp) {
      swal(this.$t('attentiontxt'), this.$t(resp.msg), 'error')
      this.modalinprogress = false;
    },
    closeModalAlertPostUpsertReq(resp) {
      if (!resp.success) {
        swal(this.$t('attentiontxt'), this.$t(resp.msg), 'warning')
        this.modalinprogress = false;
      } else {
        this.$toast.success(this.$t(resp.msg))
        this.modalinprogress = false;
        this.modalDescription = false
        this.model = {
          amount: 100, method: null, instruction: null
        };
        // Get User's Withdrawals if any
        this.$getUserWithdrawal(this.ueml).then(this.loadedWithdrawals, this.failop);
      }
    },
    getExchangeRate() {
      let currency = this.workingCurrency.toUpperCase()
      if (currency == 'EUR') {
        return this.rates.EUR;
      }
      return 1;
    },
    getCurr() {
      if (this.exchange.currency == 'crypto') {
        return 'usd';
      }
      return this.exchange.currency
    },
    writeDescription() {
      if (this.funds_received <= 0) {
        swal(this.$t('attentiontxt'), this.$t('negative_amount_for_withdrawal'), 'warning')
        return
      }
      this.modalDescription = true
    },
    sendreq() {
      this.modal_alert = false;
      this.modalinprogress = true;
      let amt = parseFloat(this.model.amount);
      if (amt && !isNaN(amt) && amt > 0) {
        let meth = this.model.method;
        this.$sendWithdrawRequest({
          userid: this.ueml,
          amount: this.withdrawalAmount,
          currency: this.getCurr(),
          payment_method: this.selectedwithdrawalsMethods,
          description: this.model.instruction,
          exchange_rate: this.getExchangeRate(),
        }).then(this.closeModalAlertPostUpsertReq, this.failWith);
      } else {
        this.failop();
      }
    },
    calcByChangeCurrencyWith() {
      if (this.exchange.withdrawal.hko === undefined || this.exchange.withdrawal.hko.length <= 0) {
        this.exchange.withdrawal.hko = 1;
      }
      if (this.exchange.withdrawal.hko > 1) {
        this.calcByCurrencyWith();
      } else {
        this.calcByHKOWith();
      }
    },
    calcByCurrencyWith() {
      if (this.exchange.currency === 'brl') {
        this.rates.withdrawal.exchange_curr = parseFloat(this.rates.withdrawal.BRL * .97).toFixed(2);
        this.exchange.withdrawal.exchange = parseFloat(this.exchange.withdrawal.hko) * parseFloat(this.rates.withdrawal.BRL * .97);
      }
      if (this.exchange.currency === 'eur') {
        this.rates.withdrawal.exchange_curr = parseFloat(this.rates.withdrawal.EUR / 1.01).toFixed(2);
        this.exchange.withdrawal.exchange = this.exchange.withdrawal.hko * parseFloat(this.rates.withdrawal.EUR / 1.01);
      }
      if (this.exchange.currency === 'usd') {
        this.rates.withdrawal.exchange_curr = parseFloat(this.rates.withdrawal.USD);
        this.exchange.withdrawal.exchange = this.exchange.withdrawal.hko * this.rates.withdrawal.exchange_curr;
      }
      this.exchange.exchange = this.exchange.withdrawal.exchange.toFixed(2);
    },
    calcByHKOWith() {
      if (this.exchange.currency === 'brl') {
        this.rates.withdrawal.exchange_curr = parseFloat(this.rates.withdrawal.BRL * 0.97).toFixed(2);
        this.exchange.withdrawal.hko = parseFloat(this.exchange.withdrawal.exchange) / parseFloat(this.rates.withdrawal.BRL * .97);
      }
      if (this.exchange.currency === 'eur') {
        this.rates.withdrawal.exchange_curr = parseFloat(this.rates.withdrawal.EUR / 1.01).toFixed(2);
        this.exchange.withdrawal.hko = parseFloat(this.exchange.withdrawal.exchange) / parseFloat(this.rates.withdrawal.EUR / 1.01);
      }
      if (this.exchange.currency === 'usd') {
        this.rates.withdrawal.exchange_curr = parseFloat(this.rates.withdrawal.USD);
        this.exchange.withdrawal.hko = parseFloat(this.exchange.withdrawal.exchange) / parseFloat(this.rates.withdrawal.USD);
      }

      this.exchange.withdrawal.hko = this.exchange.withdrawal.hko.toFixed(2);
    },
    // Top-Up
    calcByChangeCurrency() {
      if (this.exchange.hko === undefined || this.exchange.hko.length <= 0) {
        this.exchange.hko = 1;
      }
      if (this.exchange.hko > 1) {
        this.calcByCurrency();
      } else {
        this.calcByHKO();
      }
    },
    calcByCurrency() {
      if (this.exchange.currency === 'brl') {
        this.rates.exchange_curr = parseFloat(this.rates.BRL * 1.04).toFixed(2);
        this.exchange.exchange = parseFloat(this.exchange.hko) * parseFloat(this.rates.BRL * 1.04);
      }
      if (this.exchange.currency === 'eur') {
        this.rates.exchange_curr = parseFloat(this.rates.EUR * 1.01).toFixed(2);
        this.exchange.exchange = this.exchange.hko * parseFloat(this.rates.EUR * 1.01);
      }
      if (this.exchange.currency === 'usd') {
        this.rates.exchange_curr = parseFloat(this.rates.USD);
        this.exchange.exchange = this.exchange.hko * parseFloat(this.rates.USD);
      }
    },
    calcByHKO() {
      if (this.exchange.currency === 'brl') {
        this.rates.exchange_curr = parseFloat(this.rates.BRL * 1.04).toFixed(2);
        this.exchange.hko = parseFloat(this.exchange.exchange) / parseFloat(this.rates.BRL * 1.04);
      }
      if (this.exchange.currency === 'eur') {
        this.rates.exchange_curr = parseFloat(this.rates.EUR * 1.01).toFixed(2);
        this.exchange.hko = parseFloat(this.exchange.exchange) / parseFloat(this.rates.EUR * 1.01);
      }
      if (this.exchange.currency === 'usd') {
        this.rates.exchange_curr = parseFloat(this.rates.USD);
        this.exchange.hko = parseFloat(this.exchange.exchange) / parseFloat(this.rates.USD);
      }
    },
    closeModalAlertPostUpsert() {
      this.modalinprogress = false;
      this.modal = false;
      this.modal_alert = false;
      this.modal_alert_msg = null;
      if (this.adminusr) {
        let user_data = JSON.parse(localStorage.getItem('user-info'));
        this.myentry = {
          exchange: null,
          currency: null,
          description: null,
          min_sale: null,
          max_sale: 10,
          public_viewed: false
        };
      }
      this.selling = true;
      this.myentry.type = null;
      this.loadAll();
    },
    closeModalConfirmAlert() {
      this.modalinprogress = false;
      this.modalconfirm = false;
      this.modal_alert = false;
      this.modal_alert_msg = null;
      if (this.adminusr) {
        let user_data = JSON.parse(localStorage.getItem('user-info'));
        this.myentry = {
          exchange: null,
          currency: null,
          description: null,
          min_sale: null,
          max_sale: 10,
          public_viewed: false
        };
      }
    },
    closeModalAlert() {
      this.modalinprogress = false;
      this.modal = false;
      this.modal_alert = false;
      this.modal_alert_msg = null;
      this.selling = true;
      this.myentry.type = null;
    },
    openModalWith(userid) {
      this.selling = false;
      this.openModal(userid);
    },
    openModal(userid) {
      if (this.adminusr) {
        let uidx = this.tableData.findIndex(t => t.userid == userid);
        if (uidx >= 0) {
          this.newentry = false;
          this.myentry = Object.assign({}, this.tableData[uidx]);
          this.myentry['public_viewed'] = true;
          user_data = {'email': userid};
        }
      }
      this.modal = true;
    },
    deleteEntry(email, type) {
      this.modalconfirm = true;
      this.typefordel = type;
      if (this.adminusr) {
        user_data = {'email': email};
      }
    },
    confirmDeletion() {
      this.modalinprogress = true;
      let param = {
        'userid': user_data.email,
        'currency': this.exchange.currency
      }
      this.$delMarketEntry(param).then(this.closeModalConfirmAlert, this.failop).then(this.loadAll, this.failop);
    },
    loadAll() {
      entries = [];
      //this.marketentries = [];
      //this.rowentries = [];
      this.tableData = []; // table for top up ?
      this.withtableData = []; // table for withdarw ?

      const loadedEntries = resp => {
        if (!resp.success) {
          return;
        }
        entries = resp.data;
        this.loadUsers()
      };
      // Get Marketplace Entries
      this.$getMarketEntries()
          .then(loadedEntries, this.failop);
    },
    loadUsersWith() {
      this.tableData = entries
      // Sort
      //this.tableData.sort(this.sortByCol('exchange'));
      //this.tableData.sort(this.sortByCol('fullname'));
      this.clonedTableData = this.tableData.slice(0);
      // Reset it All
      this.tabchanged(this.exchange.currency);
      // Get User's Withdrawals if any
      this.$getUserWithdrawal(this.ueml).then(this.loadedWithdrawals, this.failop);
      //this.inprogress = false;
    },
    loadedWithdrawals(resp) {
      if (!resp.success) {
        return
      }
      // this.model.limit = resp.data.withdrawal_limit.toFixed(2);
      this.model.limit = 10000
      const approved = resp.data.withdrawals.approved;
      const pending = resp.data.withdrawals.pending;
      const rejected = resp.data.withdrawals.rejected;

      this.tableWithdrawals = [];
      this.tableWithdrawals = approved.concat(pending.concat(rejected));
      for (var i = 0; i < this.tableWithdrawals.length; i++) {
        this.tableWithdrawals[i]['amount_to_paid'] = parseFloat(this.tableWithdrawals[i]['amount_to_paid']).toFixed(2)
      }
      this.tableWithdrawals.sort(this.sortIt)
      this.clonedWithdrawalTableData = this.tableWithdrawals.slice(0);

      this.inprogress = false;
    },
    sortIt(a, b) {
      if (b['request_dt'] < a['request_dt'])
        return -1;
      if (b['request_dt'] > a['request_dt'])
        return 1;
      return 0;
    },
    loadUsers(resp) {
      this.clonedWithTableData = this.withtableData.slice(0);
      // Reset again
      entries = [];
      const loadedEntries = resp => {
        if (!resp.success) {
          return;
        }
        entries = resp.data;
        this.loadUsersWith();
      };
      // Get Marketplace Entries
      this.$getMarketEntries("buy")
          .then(loadedEntries, this.failop);
    },
    failop(error) {
    },
    validate() {
      this.modal_alert = false;
      this.modalinprogress = true;
      // When success
      const successop = resp => {
        //this.modalinprogress = false;
        this.modal_alert = true;
        this.modal_alert_type = 'success';
        if (this.newentry) {
          this.modal_alert_msg = this.$t('helper_newentry_done');
        } else {
          this.modal_alert_msg = this.$t('helper_upentry_done');
        }
        setTimeout(this.closeModalAlertPostUpsert, timeoutcount);
      };
      // IF Failed
      const failop_xhr = error => {
        this.modalinprogress = false;

        this.modal_alert = true;
        this.modal_alert_type = 'danger';

        if (error.data !== undefined && error.data !== null && error.data.msg !== undefined && error.data.msg !== null) {
          this.modal_alert_msg = error.data.msg;
        } else {
          if (this.newentry) {
            this.modal_alert_msg = this.$t('helper_newentry_fail');
          } else {
            this.modal_alert_msg = this.$t('helper_upentry_fail');
          }
        }
        // Reset
        this.selling = true;
        this.myentry.type = null;
      };
      // Validation
      //if (this.myentry.exchange === null || this.myentry.currency === null  || this.myentry.description === null  ||
      //    this.myentry.min_sale === null  || this.myentry.max_sale === null) {
      let proceed = false;

      if (this.myentry.public_viewed) {
        if (this.selling && this.myentry.min_sale >= 10) {
          this.myentry.type = 'buy';
          proceed = true;
        } else {
          if (this.myentry.max_sale >= 10) {
            this.myentry.type = 'sell';
            proceed = true;
          }
        }
      }

      //if ((this.myentry.max_sale >= 10 || this.myentry.min_sale >= 10) && this.myentry.public_viewed) {
      if (proceed) {
        let exchange_curr = this.rates.exchange_curr;
        if (this.selling) {
          exchange_curr = this.rates.withdrawal.exchange_curr;
        }
        this.myentry.exchange = exchange_curr;
        this.myentry.currency = this.exchange.currency.toUpperCase();
        this.myentry.description = '';

        this.$postMarketEntry(user_data, this.myentry, this.newentry)
            .then(successop, failop_xhr);
      } else {
        this.modal_alert = true;
        this.modal_alert_msg = this.$t('populatefieldbelow');
        this.modalinprogress = false;
      }
    },
    loadProjections(resp) {
      if (!resp.success) {
        return;
      }
      let data = resp.data;

      let suggestedtopup = 0.00;
      let countfol = 0;
      let countnonfol = 0;
      for (var i = 0; i < this.baccs.accounts.length; i++) {
        if (this.baccs.accounts[i].follower) {
          suggestedtopup += this.baccs.accounts[i].unbilled;
          countfol += 1;
        } else {
          countnonfol += 1;
        }
      }

      const current = {
        type: 'info',
        icon: 'nc-icon nc-money-coins',
        title: this.$t('currentbalancealt'),
        value: data.balance + " $",
        balance: parseFloat(data.balance),
        footerText: this.$t('currentbalancealt_footer'),
        footerIcon: 'nc-icon nc-alert-circle-i'
      };
      this.statsCards.push(current);

      // Paymnet Instructions
      this.tablePaymentInstruction = [
        {
          name: 'PayPal',
          instructionus: this.$t('paypalinstructions'),
          instructioneur: this.$t('paypalinstructions'),
          cost: "4%"
        }
      ];
      this.inprogress = false;
    },
    rateLoaded(resp) {
      this.rates = Object.assign({}, resp.data);
      this.rates.USD = 1.01;
      this.rates.withdrawal = Object.assign({}, resp.data);
      this.rates.withdrawal.USD = 0.99;
      this.calcByCurrencyWith();

      this.baccs = {
        accounts: []
      }
      this.$getWalletProjection(user_data.email)
          .then(this.loadProjections, this.loadFail);
    },
    async acreated() {
      // auto behaviour when navigate to this route with a given order set as route parameter
      if (this.$route.params.hasOwnProperty('order')) {
        // open payment modal if possible
        const order = this.$route.params.order;
        if (this.canPay(order)) {
          this.togglePaymentModal(order);
        }
        // auto filter and open advanced search
        // this.filters.transaction_id = order.transaction_id;
        // this.$nextTick(() => {
        //   this.$refs.module.toggleSide();
        // });
      }
    },
  },
  beforeMount() {
    if (user_data['roles'].indexOf('admin') >= 0) {
      this.adminusr = true;
    }
    if (user_data['roles'].indexOf('broker') >= 0) {
      this.isbroker = true;
      this.exchange.currencies.pop();
    }
  },
  mounted() {
    this.loadAll();

    this.ueml = user_data.email;
    // Wallet Balance
    this.walletbal = VueCookies.get('dash-wallet');

    this.$getExchangeRates()
        .then(this.rateLoaded, this.failop);
  },
  created() {
    this.$setTranslatingInProgress();
    this.acreated();

    if (rules.removeIfUserHas(['Neteller'])) {
      this.paymethods = this.paymethods.filter(item => {
        return item.prop != 'neteller' && item.prop != 'skrill';
      });
      this.tablePaymentInstruction = this.tablePaymentInstruction.filter(item => {
        return item.name != 'Neteller' && item.name != 'Skrill';
      });
      this.withdrawals.forEach(item => {
        item.currency.methods = item.currency.methods.filter(method => {
          return method.method != 'Neteller' && method.method != 'Skrill';
        });
      })
    }
  },
  beforeDestroy() {
    this.$root.$off("showTranslatingProgress")
  },
  watch: {
    'paypalValue': function (newVal) {
      this.$root.$emit('paypalUpdate', newVal);
    },
    'paypalHko': function (newVal) {
      if (newVal > 0) {
        this.failPayPalValue = false;
      }
      if (this.workingCurrency == 'eur') {
        let roundRate = parseFloat(parseFloat(this.rates.EUR + 0.01).toFixed(2));
        newVal = ((newVal * roundRate) + 0.35) / 0.962;
      }
      if (this.workingCurrency == 'usd') {
        newVal = (newVal + 0.3) / 0.949;
      }
      this.paypalValue = newVal;
    }
  }
}
</script>

<style scoped lang="scss">
#order-management {
  img {
    border: 1px solid lightgrey;
    margin: 2rem 0;
  }

  pre {
    max-height: 300px;
  }
}

.adjust-width {
  width: 325px;
}

.fa-huge {
  font-size: 9em;
}

.move-up-abit {
  margin: 0;
}

.table-responsive {
  overflow: hidden;
}

.allupper {
  text-transform: uppercase;
}

.left-pane {
  border-right: 1px solid gray;
}

.move-center {
  position: relative;
  top: 10px;
}
</style>
