<template>
  <div class="form-group">
    <label>{{ $t('status') }}</label>
    <el-select v-model="model" v-on="$listeners" v-bind="$attrs">
      <el-option value="">
        {{ $t('helper_allocationmethodtxt') }}
      </el-option>
      <el-option v-for="status in statusOptions" :key="status.value" :value="status.value">
        {{ status.label }}
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { OrderStatus } from "@/models/wallet/OrderStatus"

export default {
  props: {
    value: {
      type: String,
      default: '',
    }
  },
  computed: {
    statusOptions() {
      return OrderStatus;
    }
  },
  data() {
    return {
      model: this.value,
    }
  },
  methods: {
    handleInput(value) {
      if(typeof value === 'string') {
        this.model = value;
      }
      this.$emit('input', this.model)
    },
  },
}
</script>

<style scoped lang="scss">
@import './src/assets/sass/paper/variables';
label {
  font-size: $font-size-small;
  margin-bottom: 5px;
  color: $dark-gray;
}
</style>
