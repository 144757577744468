import {i18n} from 'src/assets/i18n-plugin.js';

export const OrderStatus = [
  {
    value: 'opened',
    label: i18n.t('opened'),
    color: 'default',
    canPay: false,
  },
  {
    value: 'processing',
    label: i18n.t('processing'),
    color: 'warning',
    canPay: true,
  },
  {
    value: 'pending',
    label: i18n.t('pendingtxt'),
    color: 'warning',
    canPay: true,
  },
  {
    value: 'expired',
    label: i18n.t('expired'),
    color: 'danger',
    canPay: false,
  },
  {
    value: 'filled',
    label: i18n.t('filled'),
    color: 'success',
    canPay: false,
  },
  {
    value: 'rejected',
    label: i18n.t('rejected'),
    color: 'danger',
    canPay: false,
  },
  {
    value: 'canceled',
    label: i18n.t('canceled'),
    color: 'default',
    canPay: false,
  },
];
